<template>
  <div>
    <QuickBooksInfo />
    <router-view />
  </div>
</template>

<script>
import QuickBooksInfo from '@/components/quick-books/QuickBooksInfo.vue';

export default {
  name: 'QuickBooksLayout',
  components: { QuickBooksInfo },
};
</script>
