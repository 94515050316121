<template>
  <div class="SettlementPeriodCell">
    <div class="d-flex flex-column align-center">
      <span v-text="value.settlementPeriod" />
      <span class="SettlementPeriodCell-Label">Period</span>
    </div>
    <div class="d-flex flex-column align-center">
      <span class="text-capitalize" v-text="value.settlementPeriodUnit" />
      <span class="SettlementPeriodCell-Label">Period unit</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettlementPeriodCell',
  props: {
    value: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.SettlementPeriodCell {
  display: flex;
  justify-content: center;
  gap: 35px;

  &-Label {
    font-size: 10px;
    line-height: 1;
  }
}
</style>
