<template>
  <div class="square-of-dots" />
</template>

<script>
export default {
  name: 'AppPreloader',
};
</script>

<style lang="scss">
.square-of-dots:before {
  -webkit-animation: square-of-dots 1s infinite linear;
  animation: square-of-dots 1s infinite linear;
  border-radius: 100%;
  content: "";
  height: 10px;
  left: 50%;
  margin-left: -47.5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 10px;
}

@-webkit-keyframes square-of-dots {
  0% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  10% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  20% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 -10px #e6a23c,
      45px -15px 0 -10px #e6a23c, 45px 0 0 -10px #e6a23c,
      45px 15px 0 -10px #e6a23c, 45px 30px 0 -10px #e6a23c,
      60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 -10px #e6a23c,
      45px -15px 0 -10px #e6a23c, 45px 0 0 -10px #e6a23c,
      45px 15px 0 -10px #e6a23c, 45px 30px 0 -10px #e6a23c,
      60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  30% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  40% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  50% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  60% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  70% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  80% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  90% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 0 #e6a23c;
  }
  100% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
}

@keyframes square-of-dots {
  0% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  10% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  20% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 -10px #e6a23c,
      45px -15px 0 -10px #e6a23c, 45px 0 0 -10px #e6a23c,
      45px 15px 0 -10px #e6a23c, 45px 30px 0 -10px #e6a23c,
      60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 -10px #e6a23c,
      45px -15px 0 -10px #e6a23c, 45px 0 0 -10px #e6a23c,
      45px 15px 0 -10px #e6a23c, 45px 30px 0 -10px #e6a23c,
      60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  30% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 -10px #e6a23c, 60px 0 0 -10px #e6a23c,
      60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  40% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 -10px #e6a23c;
  }
  50% {
    -webkit-box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 0 #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  60% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 0 #e6a23c,
      30px -15px 0 0 #e6a23c, 30px 0 0 0 #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  70% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c, 45px -30px 0 0 #e6a23c,
      45px -15px 0 0 #e6a23c, 45px 0 0 0 #e6a23c, 45px 15px 0 0 #e6a23c,
      45px 30px 0 0 #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  80% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 0 #e6a23c, 60px 0 0 0 #e6a23c,
      60px -15px 0 0 #e6a23c, 75px 0 0 0 #e6a23c;
  }
  90% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 0 #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 0 #e6a23c;
  }
  100% {
    -webkit-box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
    box-shadow: 15px 0 0 -10px #e6a23c, 30px 15px 0 -10px #e6a23c,
      30px -15px 0 -10px #e6a23c, 30px 0 0 -10px #e6a23c,
      45px -30px 0 -10px #e6a23c, 45px -15px 0 -10px #e6a23c,
      45px 0 0 -10px #e6a23c, 45px 15px 0 -10px #e6a23c,
      45px 30px 0 -10px #e6a23c, 60px 15px 0 -10px #e6a23c,
      60px 0 0 -10px #e6a23c, 60px -15px 0 -10px #e6a23c, 75px 0 0 -10px #e6a23c;
  }
}
</style>
