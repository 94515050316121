<template>
  <div class="table">
    <div class="table__header">
      <div class="table__label">
        {{ tableName }}
      </div>
      <div class="d-flex">
        <slot name="header.button" />
        <div class="table__actions">
          <el-button
            v-if="!horizontalFilters && hasFilters"
            round
            size="mini"
            @click="visibleDrawer"
          >
            <i class="mdi mdi-filter-variant mr-1" />
            Filters
          </el-button>
          <el-popover
            placement="bottom-end"
            width="max-content"
            trigger="click"
          >
            <template #default>
              <AppTableColumnFilter
                :name="tableName"
                :original-columns="originalColumns"
                @loading="loadingHandler"
                @change-columns="changeColumnsHandler"
              />
            </template>
            <el-button
              slot="reference"
              circle
              size="mini"
              icon="el-icon-setting"
            />
          </el-popover>
          <el-button
            :loading="loading"
            circle
            icon="el-icon-refresh"
            size="mini"
            @click="getData"
          />
        </div>
      </div>
    </div>
    <AppTableDataFilter
      v-if="horizontalFilters && hasFilters"
      class="table__filter table__filter_horizontal"
      :filters="filters"
      :query-key="queryKey"
      @filter="onFilter"
    />
    <div class="table__body">
      <el-table
        v-loading="loading || localLoading"
        ref="AppCursorTable"
        class="w-full"
        :key="localLoading"
        :data="data"
        :show-header="showHeader"
        :height="height"
        :empty-text="getEmptyText"
        :show-summary="showSummary"
        :summary-method="summaryMethod"
        :row-class-name="rowClassName"
        border
        highlight-selection-row
        highlight-current-row
        size="mini"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <slot name="column.selection" />
        <el-table-column
          v-for="header in displayColumns"
          :key="header.value"
          :prop="header.value"
          :label="header.text"
          :min-width="header.minWidth"
          :width="header.width"
          :fixed="header.fixed"
          :sortable="handleSortableColumn(header.sortable)"
          :align="header.align"
          :show-overflow-tooltip="header.overflow"
        >
          <template v-if="header.slot" v-slot="{ row }">
            <slot :name="header.value" :item="row" />
          </template>
          <template v-else-if="header.formatDate" v-slot="{ row }">
            {{ formatUnixToString(row[header.value], true) }}
          </template>
          <template v-else-if="header.value === 'actions'" v-slot="{ row }">
            <slot name="column.actions" :item="row" />
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="!horizontalFilters && hasFilters"
        v-show="isVisibleDrawer"
        class="table__settings"
        :style="getHeight"
      >
        <AppTableDataFilter
          class="table__filter table__filter_vertical"
          :filters="filters"
          :query-key="queryKey"
          @filter="onFilter"
        />
      </div>
    </div>
    <el-pagination
      v-if="showPagination"
      background
      small
      layout="total, sizes, prev, pager, next"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="limit"
      :total="total"
      class="table__pagination"
      @size-change="handlePageSizeChange"
      @current-change="handleCurrentPageChange"
    />
  </div>
</template>

<script lang="js">
import { cloneDeep, isEmpty } from 'lodash';
import { formatUnixToString } from '@/utils';
import AppTableColumnFilter from '@/components/app/table/AppTableColumnFilter.vue';
import AppTableDataFilter from '@/components/app/table/AppTableDataFilter.vue';

export default {
  name: 'AppTable',
  components: { AppTableColumnFilter, AppTableDataFilter },
  props: {
    tableName: { type: String },
    queryKey: { type: String },
    data: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    loading: { type: Boolean, required: true },
    filters: { type: Array, default: () => [] },
    total: { type: Number },
    height: { type: [String, Number] },
    showHeader: { type: Boolean, default: true },
    showPagination: { type: Boolean, default: true },
    horizontalFilters: { type: Boolean, default: false },
    showSummary: { type: Boolean, default: false },
    summaryMethod: { type: Function },
    rowClassName: { type: [Function, String], default: () => '' },
  },
  data: () => ({
    originalColumns: [],
    displayColumns: [],
    localLoading: false,
    isVisibleDrawer: false,
    args: {},
    sortBy: null,
    sortOrder: null,
    oldFiltersValues: {},
    filterList: {},
    pageSize: 50,
    currentPage: 0,
    pageSizes: [5, 15, 25, 50, 100, 500, 1000],
    limit: 25,
    offset: 0,
  }),
  created() {
    this.setCacheOriginalColumns();
    this.initializeDefaultFilters();

    this.getData();
  },
  computed: {
    getEmptyText() {
      return `No data in «${this.tableName}»`;
    },
    getHeight() {
      return `height: ${this.height}px`;
    },
    hasFilters() {
      return !!this.filters.length;
    },
  },
  methods: {
    formatUnixToString,
    reRenderLayout() {
      this.$refs.AppCursorTable.doLayout();
    },
    // Инициализиция фильтров по умолчанию
    initializeDefaultFilters() {
      const result = {};

      this.filters.forEach((filter) => {
        if (filter.defaultValue && filter.type === 'select') {
          result[filter.value] = filter.defaultValue;
        }
      });

      this.filterList = result;
    },
    handlePageSizeChange(limit) {
      this.limit = limit;

      this.setDefaultPagination();
      this.setDefaultScrollPosition();
      this.getData();
    },
    handleCurrentPageChange(page) {
      this.currentPage = page;
      this.offset = (page - 1) * this.limit;

      this.setDefaultScrollPosition();
      this.getData();
    },
    handleSelectionChange(selection) {
      this.$emit('selection', selection);
    },
    handleSelectionClear() {
      this.$refs.AppCursorTable.clearSelection();
    },
    handleSortChange({ prop, order }) {
      const sortType = () => {
        switch (order) {
          case 'ascending':
            return 'a';
          case 'descending':
            return 'd';
          default:
            return null;
        }
      };

      this.setDefaultPagination();
      this.setDefaultScrollPosition();

      this.sortBy = prop;
      this.sortOrder = sortType();

      this.getData();
    },
    handleSortableColumn(sortable) {
      return sortable ? 'custom' : false;
    },
    visibleDrawer() {
      this.isVisibleDrawer = !this.isVisibleDrawer;
    },
    setDefaultScrollPosition() {
      const tableRef = this.$refs.AppCursorTable.$el;
      const tableBodyWrapper = tableRef.querySelector(
        '.el-table__body-wrapper',
      );

      tableBodyWrapper.scrollTo({ top: 0 });
    },
    setDefaultPagination() {
      this.offset = 0;
      this.currentPage = 0;
    },
    setCacheOriginalColumns() {
      this.originalColumns = cloneDeep(this.columns);
    },
    changeColumnsHandler(columns) {
      this.displayColumns = columns;
    },
    loadingHandler(event) {
      this.localLoading = event;
    },
    onFilter() {
      this.setDefaultPagination();

      this.getData();
    },
    getData() {
      this.args = {
        limit: this.limit,
        offset: this.offset,
      };

      if (this.sortOrder) {
        this.args.sort = `${this.sortBy}:${this.sortOrder}`;
      }

      const selectFilterList = !isEmpty(this.$route.query[this.queryKey])
        ? JSON.parse(this.$route.query[this.queryKey])
        : {};

      this.$emit('fetch-data', { ...this.args, ...selectFilterList });
    },
  },
};
</script>
