<template>
  <div>
    <el-checkbox-group v-model="checkedColumns">
      <draggable class="grid gap-1" :list="columns">
        <div v-for="column in columns" :key="column.value">
          <el-checkbox
            class="Checkbox"
            size="mini"
            :label="column.value"
            title="Make column visible"
            :disabled="column.disabled || column.value === 'actions'"
          >
            <i class="mdi mdi-dots-grid font-size-14 mr-1" />
            {{ column.text }}
          </el-checkbox>
        </div>
      </draggable>
    </el-checkbox-group>
    <div class="d-flex mt-3">
      <el-button class="w-full" size="mini" plain @click="onResetColumns">
        Reset
      </el-button>
      <el-button
        class="w-full"
        type="primary"
        size="mini"
        plain
        @click="onSaveColumns"
      >
        Apply
      </el-button>
    </div>
  </div>
</template>

<script lang="js">
import { cloneDeep } from 'lodash';
import draggable from 'vuedraggable';

export default {
  name: 'AppTableColumnFilter',
  props: {
    name: { type: String },
    originalColumns: { type: Array, default: () => [] },
  },
  components: { draggable },
  data: () => ({
    localStorageKey: '',
    columns: [],
  }),
  created() {
    this.setLocalStorageKey();
    this.setColumns();
  },
  computed: {
    bindColumns() {
      return this.columns.filter((column) => column.visible);
    },
    checkedColumns: {
      get() {
        return this.bindColumns.map((column) => column.value);
      },
      set(checked) {
        this.columns.forEach((column) => {
          column.visible = !!checked.includes(column.value);
        });
      },
    },
  },
  methods: {
    setColumns() {
      const columnsLocalStorage = JSON.parse(
        localStorage.getItem(this.localStorageKey),
      );

      const getColumns = () => {
        if (columnsLocalStorage && this.originalColumns.length) {
          return columnsLocalStorage;
        }

        return cloneDeep(this.originalColumns);
      };

      this.columns = getColumns();

      this.changeColumnsEmit();
    },
    onResetColumns() {
      if (!localStorage.getItem(this.localStorageKey)) {
        this.$notify.error({
          title: 'Attention',
          message: "You don't have saved column settings in the system",
        });

        return;
      }

      this.loadingEmit();

      localStorage.removeItem(this.localStorageKey);
      this.columns = cloneDeep(this.originalColumns).concat();

      this.submitColumnChanges();
    },
    onSaveColumns() {
      this.loadingEmit();

      localStorage.setItem(this.localStorageKey, JSON.stringify(this.columns));

      this.submitColumnChanges();
    },
    loadingEmit(isLoading = true) {
      this.$emit('loading', isLoading);
    },
    changeColumnsEmit() {
      this.$emit('change-columns', this.bindColumns);
    },
    submitColumnChanges() {
      setTimeout(() => {
        this.changeColumnsEmit();

        this.loadingEmit(false);
      }, 1000);
    },
    setLocalStorageKey() {
      this.localStorageKey = `${this.name
        .split(' ')
        .join('_')
        .toLowerCase()}_settings`;
    },
  },
};
</script>
