import { GameProvidersApi } from '@/api/game-providers.api';
import router from '@/router';
import { Notification } from 'element-ui';

export default {
  namespaced: true,
  state: {
    gpAccountFeeConfigList: [],
    gpAccountFeeConfigListParams: {},
    gpAccountFeeConfigListCount: 0,
    isLoadingGpAccountFeeConfigList: false,
    isVisibleForm: false,
    editableGpAccountFeeId: null,
  },
  getters: {
    isEditableGpAccountForm: (state) => !!state.editableGpAccountFeeId,
  },
  mutations: {
    setGpAccountFeeConfigList: (state, { data, count }) => {
      state.gpAccountFeeConfigList = data;
      state.gpAccountFeeConfigListCount = count;
    },
    setGpAccountFeeConfigListParams: (state, params = {}) => {
      if (!Object.keys(params).length) {
        return;
      }

      state.gpAccountFeeConfigListParams = params;
    },
    setVisibleGpAccountFeeForm: (state, isVisible) => {
      state.isVisibleForm = isVisible;
    },
    setGpAccountFeeIdToEdit: (state, gpAccountFeeId) => {
      state.editableGpAccountFeeId = gpAccountFeeId;
    },
    setLoadingGpAccountFeeConfigList: (state, isLoading) => {
      state.isLoadingGpAccountFeeConfigList = isLoading;
    },
  },
  actions: {
    selectGgAccountFeeIdToEdit({ commit }, gpAccountFeeId = null) {
      commit('setGpAccountFeeIdToEdit', gpAccountFeeId);
    },
    visibleGpAccountFeeForm({ commit }, { isVisible }) {
      commit('setVisibleGpAccountFeeForm', isVisible);
    },
    getGpAccountFeeConfigList({ state, commit }, params = {}) {
      commit('setLoadingGpAccountFeeConfigList', true);
      commit('setGpAccountFeeConfigListParams', params);

      const { gameProviderAccountId } = router.currentRoute.params;

      GameProvidersApi.getGpAccountFeeConfigListRequest({
        params: { ...state.gpAccountFeeConfigListParams },
        gameProviderAccountId,
      })
        .then(({ data, count }) => {
          commit('setGpAccountFeeConfigList', { data, count });
        })
        .finally(() => {
          commit('setLoadingGpAccountFeeConfigList', false);
        });
    },
    createGpAccountFeeConfig({ dispatch }, payload) {
      const { gameProviderAccountId } = router.currentRoute.params;

      GameProvidersApi.createGpAccountFeeConfigRequest({
        gpAccountId: gameProviderAccountId,
        ...payload,
      })
        .then(() => {
          Notification.success({
            title: 'Successfully',
            message: 'Fee added successfully',
          });

          dispatch('visibleGpAccountFeeForm', { isVisible: false });
          dispatch('getGpAccountFeeConfigList');
        })
        .catch((e) => {
          throw Error(e);
        });
    },
    updateGpAccountFeeConfig({ state, dispatch }, payload) {
      const { gameProviderAccountId } = router.currentRoute.params;

      const requestData = {
        gpAccountId: gameProviderAccountId,
        ...payload,
      };

      GameProvidersApi.updateGpAccountFeeConfigRequest({
        payload: requestData,
        gpAccountFeeId: state.editableGpAccountFeeId,
      })
        .then(() => {
          Notification.success({
            title: 'Successfully',
            message: 'Fee data saved successfully',
          });

          dispatch('visibleGpAccountFeeForm', { isVisible: false });
          dispatch('selectGgAccountFeeIdToEdit');
          dispatch('getGpAccountFeeConfigList');
        })
        .catch((e) => {
          throw Error(e);
        });
    },
    deleteGpAccountFeeConfig({ dispatch }, gpAccountFeeId) {
      GameProvidersApi.deleteGpAccountFeeConfigRequest(gpAccountFeeId).then(
        () => {
          Notification.success({
            title: 'Successfully',
            message: 'Fee successfully removed',
          });

          dispatch('getGpAccountFeeConfigList');
        },
      );
    },
  },
};
