export const DictionaryName = {
  GAME_PROVIDER_ACCOUNT_NAME: 'gameProviderAccountName',
  GAME_PROVIDER_ACCOUNT_SLUG: 'gameProviderAccountSlug',
  GAME_PRODUCER_SLUG: 'gameProducerSlug',
  GAME_PRODUCER_WL: 'gameProducerWl',
  GGR_FEE_TYPE: 'ggrFeeType',
  GGR_TERMS: 'ggrTerms',
  GP_GAME_FEATURE_GROUP: 'gpGameFeatureGroup',
  LOG_TYPE: 'logType',
  PSP_TRANSACTION_MANUAL_OPERATION: 'pspTransactionManualOperation',
  QUICK_BOOKS_ACCOUNT: 'quickBooksAccount',
  COUNTRY: 'country',
  FINANCE_CURRENCY: 'financeCurrency',
  PSP_FEE_TYPE: 'pspFeeType',
  CARD_SCHEME: 'cardScheme',
  DEV_CODE_TRANSACTION_STATE: 'devCodeTransactionState',
  PSP_BP_ACCOUNT: 'pspBpAccount',
  PSP_BP: 'pspBp',
  TRANSACTIONS_TAB_OPERATIONS: 'transactionsTabOperations',
  ORIGIN_CURRENCY: 'originCurrency',
  QUICK_BOOKS_COMPANY: 'quickBooksCompany',
  PSP_ACCOUNT_BALANCE_REPORT_STATUS: 'pspAccountBalanceReportStatus',
  PSP_ACCOUNT_BALANCE_REPORT_TYPE: 'pspAccountBalanceReportType',
  PAYMENT_SYSTEM: 'paymentSystem',
};
