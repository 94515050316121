<template>
  <div>
    <template v-for="filter in filters">
      <div v-if="filter.type === 'text'" :key="filter.value" class="Input">
        <small class="Input-Label" v-text="filter.name" />
        <el-input
          size="mini"
          clearable
          :type="filter.inputType"
          placeholder="Input"
          v-model="filterValues[filter.value]"
          @keyup.enter.stop.native="applySelectedFilters"
        />
      </div>
      <div v-if="filter.type === 'select'" :key="filter.value" class="Input">
        <small v-if="filter.name" class="Input-Label" v-text="filter.name" />
        <el-select
          class="w-full"
          size="mini"
          clearable
          filterable
          :multiple="filter.multiple"
          :reserve-keyword="filter.multiple"
          :allow-create="filter.allowCreate"
          :default-first-option="filter.allowCreate"
          :placeholder="filter.placeholder || 'Select'"
          v-model="filterValues[filter.value]"
          @keyup.enter.native="applySelectedFilters"
        >
          <el-option
            v-for="(item, i) in getElSelectValues(filter)"
            :key="`Select-${i}`"
            :label="item.text || item[filter.textItem]"
            :value="item.value || item[filter.valueItem]"
          >
          </el-option>
        </el-select>
      </div>
      <div
        v-if="filter.type === 'datetime' || filter.type === 'date'"
        :key="filter.value"
        class="Input"
      >
        <small class="Input-Label">{{ filter.name }}</small>
        <el-date-picker
          class="w-full"
          :placeholder="filter.placeholder || 'Select date and time'"
          :type="filter.type"
          :clearable="filter.clearable"
          value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
          size="mini"
          v-model="filterValues[filter.value]"
        />
      </div>
    </template>
    <div class="d-flex justify-end">
      <el-button
        data-v-test="searchButton"
        type="primary"
        size="mini"
        plain
        @click="applySelectedFilters"
      >
        Search
      </el-button>
    </div>
  </div>
</template>

<script lang="js">
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

export default {
  name: 'AppTableDataFilter',
  props: {
    filters: { type: Array, required: true },
    queryKey: { type: String },
  },
  data: () => ({
    filterValues: {},
  }),
  created() {
    this.setDefaultFilterValues();
  },
  computed: {
    ...mapState('dictionaryStore', [
      'devCodeTransactionState',
      'pspFeeType',
      'quickBooksCompany',
      'transactionsTabOperations',
      'quickBooksAccount',
      'country',
      'whiteLabels',
      'ggrFeeType',
      'gameProducerSlug',
      'logType',
    ]),
  },
  methods: {
    /**
     * Форматирует значение фильтра в зависимости от его типа.
     * @param {Object} options - Параметры для форматирования.
     * @param {string} options.filterKey - Ключ фильтра.
     * @param {string} options.filterType - Тип фильтра.
     * @param {Object} options.queryValues - Значения фильтров из запроса.
     * @returns {string} - Отформатированное значение фильтра.
     */
    formatFilterValues({ filterKey, filterType, queryValues }) {
      const format = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
      const allowedDatePickerKeys = ['datetime', 'date'];
      const isDatePicker = allowedDatePickerKeys.includes(filterType);

      if (isDatePicker) {
        return queryValues[filterKey]
          ? moment.unix(queryValues[filterKey]).format(format)
          : null;
      }

      return queryValues[filterKey];
    },
    /**
     * Устанавливает значения фильтров по умолчанию на основе данных из запроса.
     */
    setDefaultFilterValues() {
      if (isEmpty(this.$route.query)) {
        return;
      }

      const queryValues = JSON.parse(this.$route.query[this.queryKey]);

      this.filters.forEach(({ value: filterKey, type: filterType }) => {
        this.$set(
          this.filterValues,
          filterKey,
          this.formatFilterValues({ filterKey, filterType, queryValues }),
        );
      });
    },
    convertDateValue(value, pickerType) {
      const allowedPickerTypes = ['date', 'datetime'];

      return allowedPickerTypes.includes(pickerType)
        ? moment(value).unix()
        : value;
    },
    getElSelectValues(filter) {
      return filter.useDictionary ? this[filter.dictionaryName] : filter.values;
    },
    /**
     * Применяет выбранные фильтры, форматирует их значения и обновляет маршрут.
     */
    applySelectedFilters() {
      const filtersResult = {};

      for (const [key, value] of Object.entries(this.filterValues)) {
        const pickerType = this.filters.find(
          (filter) => filter.value === key,
        )?.type;

        if (!isEmpty(value)) {
          filtersResult[key] = this.convertDateValue(value, pickerType);
        }
      }

      const query = !isEmpty(filtersResult)
        ? { [this.queryKey]: JSON.stringify(filtersResult) }
        : {};

      this.$router.replace({ query });

      this.$emit('filter');
    },
  },
};
</script>
