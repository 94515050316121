import { api } from '@/services/http';
import { formatDatesInPayload } from '@/utils';
import { Notification } from 'element-ui';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    pspTransactions: [],
    pspTransactionsCount: 0,
    pspTransactionsIsLoading: false,
    isVisibleDrawer: false,
    tableParams: {},
  },

  mutations: {
    setPspTransactions: (state, data) => {
      state.pspTransactions = data;
    },
    setPspTransactionsCount: (state, count) => {
      state.pspTransactionsCount = count;
    },
    setPspTransactionsIsLoading: (state, isLoading = true) => {
      state.pspTransactionsIsLoading = isLoading;
    },
    setVisibleDrawer: (state, isVisible) => {
      state.isVisibleDrawer = isVisible;
    },
    setTableParams: (state, params = {}) => {
      if (!Object.keys(params).length) {
        return;
      }

      state.tableParams = params;
    },
  },

  actions: {
    visibleDrawer({ commit }, isVisible) {
      commit('setVisibleDrawer', isVisible);
    },
    setTableParams({ commit }, params) {
      commit('setTableParams', params);
    },
    async createPspTransaction({ dispatch }, data) {
      try {
        await api.post(
          '/finance/pspTransaction',
          formatDatesInPayload({
            payload: data,
          }),
        );

        Notification.success({
          title: 'Successfully',
          message: 'Transaction created',
        });

        dispatch('visibleDrawer', false);
        dispatch('getPspTransactionList');
      } catch (e) {
        Notification.error({
          title: 'Error',
          message: e,
        });
        throw Error(e);
      }
    },
    async getPspTransactionList({ commit, state }, params = {}) {
      const { pspAccountId } = router.currentRoute.params;

      commit('setPspTransactionsIsLoading');
      commit('setTableParams', params);

      try {
        const res = await api.get('/finance/pspTransaction', {
          params: {
            'filterList[pspAccountIdList]': [pspAccountId],
            ...state.tableParams,
          },
        });
        commit('setPspTransactions', res.data);
        commit('setPspTransactionsCount', res.count);
      } catch (error) {
        Notification.error({ title: 'Error', message: error?.message });
        throw Error();
      } finally {
        commit('setPspTransactionsIsLoading', false);
      }
    },
  },
};
