import router from '@/router';
import { api, auth } from '@/services/http';
import { Notification } from 'element-ui';

const ResponsesCode = {
  INVALID_LOGIN_OR_PASSWORD: 50043,
};

export default {
  namespaced: true,
  state: {
    authToken: auth.getToken() || null,
    isAuthorizationLoading: false,
    isVisibleTwoFactorModal: false,
    twoFactorSecret: {},
  },
  getters: {
    isAuthorized: (state) => !state.isVisibleTwoFactorModal && Boolean(state.authToken),
  },
  mutations: {
    setAuthToken: (state, authToken) => {
      state.authToken = authToken;
    },
    setAuthorizationLoading: (state, isLoading) => {
      state.isAuthorizationLoading = isLoading;
    },
    setVisibleTwoFactorModal: (state, isVisible) => {
      state.isVisibleTwoFactorModal = isVisible;
    },
    setTwoFactorSecret: (state, data) => {
      state.twoFactorSecret = data;
    },
  },

  actions: {
    visibleTwoFactorModal({ commit }, { isVisible }) {
      if (!isVisible) {
        commit('setTwoFactorSecret', {});
        commit('setAuthToken', null);
      }

      commit('setVisibleTwoFactorModal', isVisible);
    },
    async loginRequest({ commit, dispatch }, { email, password, code }) {
      commit('setAuthorizationLoading', true);

      try {
        const { data, code: responseCode } = await api.post('/login', {
          email,
          password,
          code,
        });

        const { token, twoFactorEnabled } = data;

        if (responseCode === ResponsesCode.INVALID_LOGIN_OR_PASSWORD) {
          throw Error();
        }

        if (!twoFactorEnabled) {
          await dispatch('getTwoFactorSecretRequest', token);

          return;
        }

        commit('setAuthToken', token);
        auth.setToken(token);
      } catch ({ message }) {
        commit('setAuthToken', null);
      } finally {
        commit('setAuthorizationLoading', false);
      }
    },
    async getTwoFactorSecretRequest({ commit, dispatch }, token) {
      try {
        api.defaults.headers.common['X-Auth-Token'] = token;

        const { data } = await api.post('/twoFactor/secret');

        dispatch('visibleTwoFactorModal', { isVisible: true });
        commit('setTwoFactorSecret', data);
      } catch (e) {
        throw Error();
      }
    },
    async logoutRequest({ commit }, { hasTokenExpired = false }) {
      try {
        if (!hasTokenExpired) {
          await api.post('/logout');
        }

        router.push('/login').then(() => {
          commit('setTwoFactorSecret', {});
          commit('setAuthToken', null);

          auth.removeToken();
        });
      } catch (e) {
        throw Error();
      }
    },
    async createTwoFactorCodeRequest({ dispatch }, { code }) {
      try {
        await api.post('/twoFactor/code', { code });

        Notification.success({
          title: 'Successfully',
          message: 'Two factor authorization activated',
        });

        dispatch('visibleTwoFactorModal', { isVisible: false });
      } catch ({ message }) {
        Notification.error({
          title: 'Error',
          message,
        });

        throw Error();
      }
    },
  },
};
