import { api } from '@/services/http';
import { DictionaryApi } from '@/api/dictionary.api';
import { DictionaryName } from '@/models/dictionary.model';

export default {
  namespaced: true,
  state: {
    pspFeeType: [],
    financeCurrency: [],
    country: [],
    cardScheme: [],
    devCodeTransactionState: [],
    pspBp: [],
    whiteLabels: [],
    originCurrency: [],
    quickBooksCompany: [],
    transactionsTabOperations: [],
    quickBooksAccount: [],
    gameProviderAccountName: [],
    gameProviderAccountSlug: [],
    gameProducerSlug: [],
    gameProducerWl: [],
    ggrFeeType: [],
    ggrTerms: [],
    logType: [],
    pspTransactionManualOperation: [],
    pspAccountBalanceReportStatus: [],
    pspAccountBalanceReportType: [],
  },
  getters: {
    // eslint-disable-next-line max-len
    getTransactionsTabOperationsName: (state) => (operation) => state.transactionsTabOperations.find((i) => i.code === operation)
      .name,
    // eslint-disable-next-line max-len
    getTransactionsTabOperationsCodeList: (state) => state.transactionsTabOperations.map((item) => item.code),
    getPspFeeTypeName: (state) => (code) => {
      // TODO: 2 новых значения вывести в отдельную ручку для Total fees
      const union = [
        ...state.pspFeeType,
        ...[
          { code: 'manualFeeWithdrawal', name: 'Manual Fee Withdrawal' },
          { code: 'manualFeeDeposit', name: 'Manual Fee Deposit' },
        ],
      ];

      return union.find((i) => i.code === code)?.name ?? '';
    },
    getGgrFeeTypeName: (state) => (code) => state.ggrFeeType.find((i) => i.code === code)?.name ?? '',
    getGgrTermsName: (state) => (code) => state.ggrTerms.find((i) => i.code === code)?.name ?? '',
    getWhiteLabelSlug: (state) => (wlId) => {
      const slug = state.whiteLabels.find(
        (whitelabel) => whitelabel.id === wlId,
      )?.slug;

      return slug || wlId;
    },
    allWhiteLabelIdList: (state) => state.whiteLabels.map((whiteLabel) => whiteLabel.id),
  },
  mutations: {
    setWhiteLabels: (state, data) => {
      state.whiteLabels = data;
    },
    setDictionary: (state, { key, data }) => {
      state[key] = data;
    },
  },
  actions: {
    getCountry({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.COUNTRY,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.COUNTRY,
          data,
        });
      });
    },
    getFinanceCurrency({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.FINANCE_CURRENCY,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.FINANCE_CURRENCY,
          data,
        });
      });
    },
    getPspFeeType({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.PSP_FEE_TYPE,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.PSP_FEE_TYPE,
          data,
        });
      });
    },
    getCardScheme({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.CARD_SCHEME,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.CARD_SCHEME,
          data,
        });
      });
    },
    getDevCodeTransactionState({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.DEV_CODE_TRANSACTION_STATE,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.DEV_CODE_TRANSACTION_STATE,
          data,
        });
      });
    },
    async getPspBpAccountList(ctx, params) {
      try {
        const { data } = await api.get('/dictionary?name=pspBpAccount', {
          params,
        });

        return data;
      } catch (e) {
        throw Error(e);
      }
    },
    getPspBp({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.PSP_BP,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.PSP_BP,
          data,
        });
      });
    },
    getTransactionsTabOperations({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.TRANSACTIONS_TAB_OPERATIONS,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.TRANSACTIONS_TAB_OPERATIONS,
          data,
        });
      });
    },
    getOriginCurrency({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.ORIGIN_CURRENCY,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.ORIGIN_CURRENCY,
          data,
        });
      });
    },
    getQuickBookCompany({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.QUICK_BOOKS_COMPANY,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.QUICK_BOOKS_COMPANY,
          data,
        });
      });
    },
    getQuickBooksAccount({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.QUICK_BOOKS_ACCOUNT,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.QUICK_BOOKS_ACCOUNT,
          data,
        });
      });
    },
    // TODO: Предложить беку перенести это барахло в dictionary
    async getWhiteLabels({ commit }) {
      try {
        const { data } = await api.get('/whitelabel');

        commit('setWhiteLabels', data);
      } catch (e) {
        throw Error(e);
      }
    },
    getGameProviderAccountName({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.GAME_PROVIDER_ACCOUNT_NAME,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.GAME_PROVIDER_ACCOUNT_NAME,
          data,
        });
      });
    },
    getGameProviderAccountSlug({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.GAME_PROVIDER_ACCOUNT_SLUG,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.GAME_PROVIDER_ACCOUNT_SLUG,
          data,
        });
      });
    },
    getGameProducerSlug({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.GAME_PRODUCER_SLUG,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.GAME_PRODUCER_SLUG,
          data,
        });
      });
    },
    getGameProducerWl({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.GAME_PRODUCER_WL,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.GAME_PRODUCER_WL,
          data,
        });
      });
    },
    getGgrFeeType({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.GGR_FEE_TYPE,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.GGR_FEE_TYPE,
          data,
        });
      });
    },
    getGgrTerms({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.GGR_TERMS,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.GGR_TERMS,
          data,
        });
      });
    },
    getLogType({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.LOG_TYPE,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.LOG_TYPE,
          data,
        });
      });
    },
    async getPaymentSystem(_, params) {
      try {
        const { data } = await api.get('/dictionary?name=paymentSystem', {
          params,
        });

        return data;
      } catch (e) {
        throw Error(e);
      }
    },
    getPspTransactionManualOperation({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.PSP_TRANSACTION_MANUAL_OPERATION,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.PSP_TRANSACTION_MANUAL_OPERATION,
          data,
        });
      });
    },
    getPspAccountBalanceReportStatus({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.PSP_ACCOUNT_BALANCE_REPORT_STATUS,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.PSP_ACCOUNT_BALANCE_REPORT_STATUS,
          data,
        });
      });
    },
    getPspAccountBalanceReportType({ commit }) {
      DictionaryApi.getDictionaryRequest({
        name: DictionaryName.PSP_ACCOUNT_BALANCE_REPORT_TYPE,
      }).then((data) => {
        commit('setDictionary', {
          key: DictionaryName.PSP_ACCOUNT_BALANCE_REPORT_TYPE,
          data,
        });
      });
    },
    /** We initialize the necessary data for the application to work */
    async initDictionary({ dispatch }) {
      await Promise.allSettled([
        dispatch('getPspFeeType'),
        dispatch('getCountry'),
        dispatch('getFinanceCurrency'),
        dispatch('getCardScheme'),
        dispatch('getPspBp'),
        dispatch('getWhiteLabels'),
        dispatch('getOriginCurrency'),
        dispatch('getQuickBookCompany'),
      ]);
    },
  },
};
