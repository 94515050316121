import { isEmpty } from 'lodash';
import router from '@/router';

/**
 * Ключи запросов для различных таблиц.
 */
export const TableQueryKeys = {
  TRANSACTIONS_CHECK_TABLE: 'transactions_check_table',
  TRANSACTIONS_TABLE: 'transactions_table',
  QUICK_BOOKS_TABLE: 'quick_books_table',
  ACTUAL_SETTLEMENT_TABLE: 'actual_settlement_table',
  TOP_UP_BALANCE_TRANSACTIONS_TABLE: 'top_up_balance_transactions_table',
  PSP_FEE_CONFIG_TABLE: 'psp_fee_config_table',
  GP_FEE_CONFIG_TABLE: 'gp_fee_config_table',
  PSP_BALANCES_REPORT_TABLE: 'psp_balances_report_table',
};

/**
 * Устанавливает фильтры по умолчанию в маршруте, если они отсутствуют.
 * @param {Object} defaultFilters - Объект с фильтрами по умолчанию.
 * @param {string} queryKey - Ключ запроса для фильтров.
 */
export const setDefaultQueryFilters = ({ defaultFilters, queryKey }) => {
  const currentQuery = router.currentRoute.query[queryKey];

  if (isEmpty(currentQuery)) {
    const defaultQuery = {
      [queryKey]: JSON.stringify(defaultFilters),
    };

    router.replace({
      query: defaultQuery,
    });
  }
};

export const pushToQuickBooksTransfer = (quickBooksTransferId) => {
  const { href } = router.resolve({
    path: '/-/quickbooks-transfer',
    query: { 'filterList[quickBooksTransferId]': quickBooksTransferId },
  });

  window.open(href, '_blank');
};
