import { api } from '@/services/http';
import { formatDatesInPayload } from '@/utils';
import { Notification } from 'element-ui';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    pspTransactionReports: [],
    isVisibleDialog: false,
    isLoadingPspTransactionReports: true,
  },
  mutations: {
    setVisibleDialog: (state, isVisible) => {
      state.isVisibleDialog = isVisible;
    },
    setIsLoadingPspTransactionReports: (state, isLoading) => {
      state.isLoadingPspTransactionReports = isLoading;
    },
    setPspTransactionReports: (state, data) => {
      state.pspTransactionReports = data;
    },
  },
  actions: {
    visibleDialog({ commit }, { isVisible }) {
      commit('setVisibleDialog', isVisible);
    },
    async createPspTransactionReportRequest(
      { dispatch },
      { formData, pspAccountId },
    ) {
      try {
        if (formData.isGeneratedBySettlementAmount) {
          delete formData.toCreatedAt;
        }

        await api.post(
          `/finance/pspTransactionReport/${pspAccountId}`,
          formatDatesInPayload({ payload: formData }),
        );

        dispatch('getPspTransactionReportsRequest');
        dispatch('visibleDialog', { isVisible: false });

        Notification.success({
          title: 'Successfully',
          message: 'The report will be created soon',
        });
      } catch ({ message }) {
        Notification.error({
          title: 'Error',
          message,
        });
      }
    },
    async deletePspTransactionReportRequest(
      { commit, dispatch },
      pspTransactionReportId,
    ) {
      commit('setIsLoadingPspTransactionReports', true);

      try {
        await api.delete(
          `/finance/pspTransactionReport/${pspTransactionReportId}`,
        );

        dispatch('getPspTransactionReportsRequest');

        Notification.success({
          title: 'Successfully',
          message: 'The report was successfully deleted',
        });
      } catch (e) {
        Notification.error({
          title: 'Error',
          message: e,
        });
      } finally {
        commit('setIsLoadingPspTransactionReports', false);
      }
    },
    async getPspTransactionReportsRequest({ commit, rootState }) {
      const pspAccountId = rootState.pspAccounts.pspAccount.pspAccountId
        || router.currentRoute.params.pspAccountId;

      if (!pspAccountId) {
        return;
      }

      commit('setIsLoadingPspTransactionReports', true);

      try {
        const { data } = await api.get(
          `/finance/pspTransactionReportStatus/${pspAccountId}`,
        );

        commit('setPspTransactionReports', data);
      } catch (e) {
        throw Error();
      } finally {
        commit('setIsLoadingPspTransactionReports', false);
      }
    },
    async closePspTransactionReportRequest(
      { dispatch, rootState },
      {
        formData, pspTransactionReportId, externalSettlementId, source,
      },
    ) {
      const formatFormData = {
        ...{
          source,
          externalSettlementId,
        },
        ...formData,
      };

      try {
        await api.post(
          `/finance/closePspTransactionReport/${pspTransactionReportId}`,
          formatDatesInPayload({ payload: formatFormData }),
        );

        Notification.success({
          title: 'Successfully',
          message: 'Report closed successfully',
        });

        dispatch('getPspTransactionReportsRequest');
        dispatch(
          'pspAccounts/getPspAccount',
          router.currentRoute.params?.pspAccountId
            || rootState.pspAccounts.pspAccount.pspAccountId,
          { root: true },
        );
      } catch (e) {
        throw Error();
      }
    },
  },
};
