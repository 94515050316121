<template>
  <div>
    <el-table
      v-if="Object.keys(pspAccount).length !== 0"
      max-height="300"
      :data="[pspAccount]"
      size="mini"
      border
      class="w-full account-info"
    >
      <el-table-column>
        <template slot="header">
          <span>
            <i class="el-icon-star-on mr-1" />
            Information
          </span>
        </template>
        <el-table-column
          label="PSP"
          prop="pspSlug"
          width="100"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span class="font-weight-bold">
              {{ row.pspSlug }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="Provider"
          prop="provider"
          width="100"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span class="font-weight-bold">
              {{ row.provider }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="PSP Accounts" prop="pspAccountList" width="120">
          <template v-slot="{ row }">
            <div v-if="row.anyBpAccount">All PSP accounts</div>
            <div v-else class="table-cell-scroll">
              <div v-for="psp in row.pspAccountList" :key="psp">
                {{ psp }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Payment system" prop="paymentSystemList" width="120">
          <template v-slot="{ row }">
            <div v-if="row.isAnyPaymentSystem">All payment systems</div>
            <div v-else class="table-cell-scroll">
              <div v-for="paymentSystem in row.paymentSystemList" :key="paymentSystem">
                {{ paymentSystem }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="whitelabelIdList"
          label="Brand"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div class="table-cell-scroll">
              <template v-if="row.whitelabelIdList.length">
                <div v-for="wlId in row.whitelabelIdList" :key="wlId">
                  {{ getWhiteLabelSlug(wlId) }}
                </div>
              </template>
              <template v-else> All brands </template>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column>
        <template #header>
          <span>
            <i class="el-icon-money mr-1" />
            Balances
          </span>
        </template>
        <el-table-column
          prop="currency"
          label="Currency"
          align="center"
          width="75"
        >
          <template v-slot="{ row }">
            {{ row.currency }}
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="Balance" show-overflow-tooltip>
          <template v-slot="{ row }">
            {{ row.balance }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualSettlement"
          label="Act. settlement"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            {{ row.actualSettlement }}
          </template>
        </el-table-column>
        <el-table-column
          prop="totalReserve"
          label="Total reserve"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            {{ row.totalReserve }}
          </template>
        </el-table-column>
        <el-table-column
          prop="totalSettlement"
          label="Total settlement"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            {{ row.totalSettlement }}
          </template>
        </el-table-column>
        <el-table-column prop="total" label="Total" show-overflow-tooltip>
          <template v-slot="{ row }">
            {{ row.total }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column>
        <template #header>
          <span>
            <i class="el-icon-date mr-1" />
            Settlement dates
          </span>
        </template>
        <el-table-column
          prop="settlementFirstDateTime"
          label="First"
          width="90"
        >
          <template v-slot="{ row }">
            <div class="account-info__date">
              {{ row.settlementFirstDateTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="settlementLastDateTime" label="Last" width="90">
          <template v-slot="{ row }">
            <div class="account-info__date">
              {{ formatUnixToString(row.settlementLastDateTime, true) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="settlementNextDateTime" label="Next" width="90">
          <template v-slot="{ row }">
            <div class="account-info__date">
              {{ formatUnixToString(row.settlementNextDateTime, true) }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="settlementConfig" align="center" width="150">
        <template #header>
          <span>
            <i class="el-icon-setting mr-1" />
            Config
          </span>
        </template>
        <template v-slot="{ row }">
          <DurationsPeriodCell
            v-if="row.settlementPeriodDuration"
            v-model="row.settlementConfig.settlementPeriodDuration"
          />
          <CronExpressionText
            v-if="row.settlementCronExpression"
            v-model="row.settlementConfig.settlementCronExpression"
          />
          <SettlementPeriodCell
            v-if="row.settlementPeriod"
            v-model="row.settlementConfig"
          />
        </template>
      </el-table-column>
    </el-table>
    <div
      v-else
      v-loading="true"
      element-loading-text="Loading for PSP account information..."
      class="account-info__loading"
    />
  </div>
</template>

<script lang="js">
import { mapState } from 'vuex';
import { formatUnixToString } from '@/utils';
import DurationsPeriodCell from '@/components/PaymentService/Accounts/TableCell/DurationsPeriodCell.vue';
import CronExpressionText from '@/components/app/forms/CronExpressionText.vue';
import SettlementPeriodCell from '@/components/PaymentService/Accounts/TableCell/SettlementPeriodCell.vue';

export default {
  name: 'PspAccountInfo',
  components: {
    SettlementPeriodCell,
    CronExpressionText,
    DurationsPeriodCell,
  },
  computed: {
    ...mapState({
      pspAccount: (state) => state.pspAccounts.pspAccount,
      whiteLabel: (state) => state.dictionaryStore.whiteLabels,
    }),
  },
  methods: {
    formatUnixToString,
    getWhiteLabelSlug(wlId) {
      return this.whiteLabel.find((i) => i.id === wlId).slug;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-info {
  &__amount {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3px;
    line-height: 1;
  }

  &__date {
    width: 75px;
    word-break: initial;
  }

  &__loading {
    height: 130px;
    border: 1px solid $border-color;
  }
}

.el-table::v-deep thead.is-group th.el-table__cell {
  background: #ffffff;

  .cell {
    word-break: initial;
  }
}
</style>
