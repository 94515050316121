import { api } from '@/services/http';
import { formatDatesInPayload } from '@/utils';
import { Notification } from 'element-ui';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    tableParams: {},
    isVisibleDrawer: false,
    topUpBalanceTransactions: [],
    topUpBalanceTransactionsCount: 0,
    topUpBalanceTransactionsIsLoading: false,
  },

  mutations: {
    setTableParams: (state, params = {}) => {
      if (!Object.keys(params).length) return;

      state.tableParams = params;
    },
    setTopUpBalanceTransactionsData: (state, { data, count }) => {
      state.topUpBalanceTransactions = data;
      state.topUpBalanceTransactionsCount = count;
    },
    setTopUpBalanceTransactionsIsLoading: (state, isLoading = true) => {
      state.topUpBalanceTransactionsIsLoading = isLoading;
    },
    setVisibleDrawer: (state) => {
      state.isVisibleDrawer = !state.isVisibleDrawer;
    },
  },

  actions: {
    async getQuickBooksTopUpBalanceTransactionsRequest(
      { commit, state },
      params = {},
    ) {
      commit('setTopUpBalanceTransactionsIsLoading');
      commit('setTableParams', params);

      const { id } = router.currentRoute.params;

      try {
        const { data, count } = await api.get(
          `/quickBooksTopUp/pspTransaction/${id}`,
          {
            params: state.tableParams,
          },
        );

        commit('setTopUpBalanceTransactionsData', { data, count });
      } catch (e) {
        throw Error();
      } finally {
        commit('setTopUpBalanceTransactionsIsLoading', false);
      }
    },
    async getTopUpBalanceTransactions({ commit, state }, params = {}) {
      commit('setTopUpBalanceTransactionsIsLoading');
      commit('setTableParams', params);

      try {
        const { pspAccountId } = router.currentRoute.params;

        const filters = {
          'filterList[pspAccountIdList]': [pspAccountId],
          'filterList[operationList]': ['topUpBalance', 'deductionBalance'],
        };

        const { data, count } = await api.get('/finance/pspTransaction', {
          params: { ...state.tableParams, ...filters },
        });

        commit('setTopUpBalanceTransactionsData', { data, count });
      } catch (e) {
        throw Error();
      } finally {
        commit('setTopUpBalanceTransactionsIsLoading', false);
      }
    },

    async createTopUpBalanceTransaction(
      { commit, dispatch },
      { isQuickBook = false, payload },
    ) {
      try {
        await api.post(
          '/finance/pspTransaction',
          formatDatesInPayload({
            payload,
          }),
        );

        Notification.success({
          title: 'Successfully',
          message: 'Transaction created',
        });

        if (isQuickBook) {
          dispatch('getQuickBooksTopUpBalanceTransactionsRequest');
          dispatch(
            'quickBooksStore/getQuickBookTransferRequest',
            {},
            { root: true },
          );

          return;
        }

        commit('setVisibleDrawer');
        dispatch('getTopUpBalanceTransactions');
      } catch (e) {
        throw Error();
      }
    },
    async toggleVisaedRequest(
      { dispatch },
      { isVisaed, comment = null, pspTransactionId },
    ) {
      const { pspAccountId } = router.currentRoute.params;

      try {
        await api.patch(
          `/finance/pspTransaction/isVisaed/${pspTransactionId}`,
          {
            pspAccountId,
            isVisaed,
            comment,
          },
        );

        dispatch('getTopUpBalanceTransactions');
      } catch (e) {
        throw Error();
      }
    },
  },
};
