import moment from 'moment-timezone';
import { Message } from 'element-ui';

export const getTableHeight = (subtractHeight = 200) => window.innerHeight - subtractHeight;

export const formatDate = (datetime, isUnix = false) => {
  if (isUnix) {
    return moment(datetime).unix();
  }
  return moment.unix(datetime).format('YYYY-MM-DD HH:mm:ss');
};

export const copyToClipboard = (data) => {
  const textArea = document.createElement('textarea');

  Message.info({ message: `Copied to clipboard ${data}` });

  textArea.value = data;
  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();

  document.execCommand?.('copy');

  textArea.blur();

  document.body.removeChild(textArea);
  navigator.clipboard?.writeText(data);
};

export const convertBooleanToString = (value) => {
  if (!value) {
    return '';
  }

  return value ? 'Yes' : 'No';
};
