import { DashboardApi } from '@/api/dashboard.api';
import { omit } from 'lodash';

export default {
  namespaced: true,
  state: {
    activeTab: 'period',
    pspAccountFilter: {
      'filterList[pspAccountIdList]': [],
    },
    pspBalancePeriodFilters: {
      'filterList[pspAccountSlug]': null,
      'filterList[toDate]': null,
      'filterList[fromDate]': null,
      scale: 'day',
    },
    pspBalanceDayFilter: {
      'filterList[pspAccountSlug]': null,
      'filterList[date]': null,
    },
    dateTimestamp: null,
    isPendingLoadingData: true,
    pspBalancesDayTable: {
      tableColumns: [],
      tableData: [],
      tableTotalData: {},
    },
    pspBalancesPeriodTable: {
      tableColumns: [],
      tableData: [],
      tableTotalData: {},
    },
  },
  mutations: {
    setUpdatedPspAccountSlugForDateFilter: (state, value) => {
      state.pspBalanceDayFilter['filterList[pspAccountSlug]'] = value || null;
    },
    setUpdatedPspAccountSlugForPeriodFilter: (state, value) => {
      state.pspBalancePeriodFilters['filterList[pspAccountSlug]'] = value || null;
    },
    setUpdatedPspAccountIdListFilter: (state, value) => {
      state.pspAccountFilter['filterList[pspAccountIdList]'] = value;
    },
    setUpdatedFromDateFilter: (state, value) => {
      state.pspBalancePeriodFilters['filterList[fromDate]'] = value;
    },
    setUpdatedToDateFilter: (state, value) => {
      state.pspBalancePeriodFilters['filterList[toDate]'] = value;
    },
    setUpdatedScaleFilter: (state, value) => {
      state.pspBalancePeriodFilters.scale = value;
    },
    setUpdatedDateFilter: (state, value) => {
      state.pspBalanceDayFilter['filterList[date]'] = value;
    },
    setUpdatedDateTimestamp: (state, value) => {
      state.dateTimestamp = value;
    },
    setTableData: (state, { tableKey, data }) => {
      state[tableKey].tableColumns = data.tableColumns;
      state[tableKey].tableData = data.tableData;
      state[tableKey].tableTotalData = data.tableTotalData;
    },
    setPendingLoadingData: (state, isLoading) => {
      state.isPendingLoadingData = isLoading;
    },
    setActiveTab: (state, tab) => {
      state.activeTab = tab;
    },
  },
  actions: {
    updatePspAccountSlugForDateFilter({ commit }, value) {
      commit('setUpdatedPspAccountSlugForDateFilter', value);
    },
    updatePspAccountSlugForPeriodFilter({ commit }, value) {
      commit('setUpdatedPspAccountSlugForPeriodFilter', value);
    },
    updatePspAccountIdListFilter({ commit }, value) {
      commit('setUpdatedPspAccountIdListFilter', value);
    },
    updateFromDateFilter({ commit }, value) {
      commit('setUpdatedFromDateFilter', value);
    },
    updateToDateFilter({ commit }, value) {
      commit('setUpdatedToDateFilter', value);
    },
    updateScaleFilter({ commit }, value) {
      commit('setUpdatedScaleFilter', value);
    },
    updateDateFilter({ commit }, value) {
      commit('setUpdatedDateFilter', value);
    },
    updateDateTimestamp({ commit }, value) {
      commit('setUpdatedDateTimestamp', value);
    },
    getPspAccountBalancePeriodListRequest({ commit, state }) {
      commit('setPendingLoadingData', true);

      if (state.pspAccountFilter['filterList[pspAccountIdList]'].length === 0) {
        commit('setPendingLoadingData', false);

        return;
      }

      DashboardApi.getPspAccountBalancePeriodList(
        {
          ...state.pspBalancePeriodFilters,
          ...state.pspAccountFilter,
        },
      )
        .then((data) => {
          commit('setTableData', { tableKey: 'pspBalancesPeriodTable', data });
        })
        .finally(() => {
          commit('setPendingLoadingData', false);
        });
    },
    getPspAccountBalanceDayListRequest({ commit, state }) {
      commit('setPendingLoadingData', true);

      if (state.pspAccountFilter['filterList[pspAccountIdList]'].length === 0) {
        commit('setPendingLoadingData', false);

        return;
      }

      DashboardApi.getPspAccountBalanceDayList(
        {
          ...state.pspBalanceDayFilter,
          ...state.pspAccountFilter,
        },
      )
        .then((data) => {
          commit('setTableData', { tableKey: 'pspBalancesDayTable', data });
        })
        .finally(() => {
          commit('setPendingLoadingData', false);
        });
    },
    handleSelectTab({ commit }, tab) {
      commit('setActiveTab', tab);
    },
    /**
     * Сохраняет фильтр аккаунта PSP в локальное хранилище и
     * вызывает соответствующий метод в зависимости от активной вкладки
    */
    savePspAccountFilter({ state, commit, dispatch }) {
      const methodCallMap = {
        period: () => dispatch('getPspAccountBalancePeriodListRequest'),
        day: () => dispatch('getPspAccountBalanceDayListRequest'),
      };

      const pspAccountSlug = omit(state.pspAccountFilter, 'filterList[pspAccountSlug]');

      // Если есть выбранные PSP accounts, делаем запросы.
      // Если нет то сбрасываем данные чтобы очистить таблицу
      if (pspAccountSlug['filterList[pspAccountIdList]'].length !== 0) {
        methodCallMap[state.activeTab]();
      } else {
        commit('setTableData', { tableKey: 'pspBalancesDayTable', data: [] });
        commit('setTableData', { tableKey: 'pspBalancesPeriodTable', data: [] });
      }

      localStorage.setItem('PSP_BALANCES_GLOBAL_FILTER', JSON.stringify(pspAccountSlug));
    },
    /**
     * Загружает сохраненные фильтры аккаунта PSP из локального хранилища и
     * обновляет состояние
     */
    loadPspAccountFilter({ dispatch }) {
      const savedFilters = localStorage.getItem('PSP_BALANCES_GLOBAL_FILTER');

      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        const pspAccountId = parsedFilters['filterList[pspAccountIdList]'];

        if (pspAccountId) {
          dispatch('updatePspAccountIdListFilter', pspAccountId);
        }
      }
    },
  },
};
