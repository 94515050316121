<template>
  <div
    class="CronExpressionText"
    v-text="cronstrue.toString(value, { use24HourTimeFormat: true })"
  />
</template>

<script>
import cronstrue from 'cronstrue';

export default {
  name: 'CronExpressionText',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cronstrue,
    };
  },
};
</script>

<style lang="scss" scoped>
.CronExpressionText {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 15px;
  font-size: 12px;

  &-Label {
    font-size: 10px;
    line-height: 1;
  }
}
</style>
