import { api } from '@/services/http';
import router from '@/router';
import { Notification } from 'element-ui';

export default {
  namespaced: true,
  state: {
    selectedPspAccountId: null,
    externalSettlementId: null,
    source: 'quickbooks',

    quickBooksSettlementsIsLoading: true,
    quickBooksSettlements: [],
    quickBooksSettlementsCount: 0,
    quickBooksSettlementsTableParams: {},

    quickBookActualSettlements: [],
    quickBookActualSettlementsCount: 0,

    quickBookTransferItem: [],
  },
  mutations: {
    setQuickBooksSettlements: (state, { data, count }) => {
      state.quickBooksSettlements = data;
      state.quickBooksSettlementsCount = count;
    },
    setQuickBooksSettlementsIsLoading: (state, isLoading) => {
      state.quickBooksSettlementsIsLoading = isLoading;
    },
    setQuickBookActualSettlements: (state, { data, count }) => {
      state.quickBookActualSettlements = data;
      state.quickBookActualSettlementsCount = count;
    },
    setQuickBooksSettlementsTableParams: (state, params = {}) => {
      if (!Object.keys(params).length) return;

      state.quickBooksSettlementsTableParams = params;
    },
    setPspAccountId: (state, pspAccountId = null) => {
      state.selectedPspAccountId = pspAccountId;
    },
    setExternalSettlementId: (state, externalSettlementId = null) => {
      state.externalSettlementId = externalSettlementId;
    },
    setQuickBooksTransferItem: (state, data) => {
      state.quickBookTransferItem = data;
    },
  },
  actions: {
    selectExternalSettlementId({ commit }, externalSettlementId) {
      commit('setExternalSettlementId', externalSettlementId);
    },
    selectPspAccountId({ commit }, pspAccountId) {
      commit('setPspAccountId', pspAccountId);
    },
    async getQuickBookActualSettlementsRequest({ commit }, params) {
      const { id } = router.currentRoute.params;

      try {
        const { data, count } = await api.get(
          `/quickBooksSettlement/actualSettlement/${id}`,
          { params },
        );

        commit('setQuickBookActualSettlements', { data, count });
      } catch (e) {
        throw Error();
      }
    },
    async getQuickBookTransferRequest({ commit }) {
      const { data } = await api.get('/quickBooksTransfer', {
        params: {
          'filterList[quickBooksTransferId]': router.currentRoute.params.id,
        },
      });

      commit('setQuickBooksTransferItem', data);
    },
    async updateUnsetDeletedStatusRequest({ dispatch }, id) {
      try {
        await api.patch(`/quickBooksTransfer/unsetDeletedStatus/${id}`);

        Notification.success({
          message: 'Successfully',
        });

        dispatch('getQuickBooksTransferRequest');
      } catch (e) {
        throw Error();
      }
    },
    async updateUnsetUpdatedStatusRequest({ dispatch }, id) {
      try {
        await api.patch(`/quickBooksTransfer/unsetUpdatedStatus/${id}`);

        Notification.success({
          message: 'Successfully',
        });

        dispatch('getQuickBooksTransferRequest');
      } catch (e) {
        throw Error();
      }
    },
    async getQuickBooksTransferRequest({ commit, state }, params = {}) {
      commit('setQuickBooksSettlementsIsLoading', true);
      commit('setQuickBooksSettlementsTableParams', params);

      try {
        const { data, count } = await api.get('/quickBooksTransfer', {
          params: state.quickBooksSettlementsTableParams,
        });

        commit('setQuickBooksSettlements', { data, count });
      } catch (e) {
        throw Error();
      } finally {
        commit('setQuickBooksSettlementsIsLoading', false);
      }
    },
    async updateQuickBooksSettlementStatusRequest(
      { dispatch },
      { status, quickBooksSettlementId },
    ) {
      try {
        await api.patch(
          `/quickBooksTransfer/status/${quickBooksSettlementId}`,
          { status },
        );

        dispatch('getQuickBooksTransferRequest');
      } catch (e) {
        throw Error();
      }
    },
  },
};
