<template>
  <div class="Duration">
    <div
      class="Duration-Period"
      v-for="(duration, period, index) in computedDuration()"
      :key="index"
    >
      <div v-text="duration" />
      <div class="Duration-Period" v-text="period" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'DurationsPeriodCell',
  props: {
    value: { type: String, required: true },
  },
  methods: {
    computedDuration() {
      const { _data } = moment.duration(this.value);
      const { days, months, years } = _data;
      return {
        years,
        months,
        days,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.Duration {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 10px;

  &-Period {
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: capitalize;
    white-space: nowrap;
  }
}
</style>
