<template>
  <div>
    <AppPreloader v-if="isLoading" />
    <el-container v-else>
      <el-header height="48px">
        <AppMenu />
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AppMenu from '@/components/app/AppMenu.vue';
import AppPreloader from '@/components/app/AppPreloader.vue';

export default {
  name: 'AppLayout',
  components: { AppPreloader, AppMenu },
  data: () => ({
    isLoading: true,
  }),
  created() {
    this.fetchRequiredData();
  },
  methods: {
    ...mapActions('dictionaryStore', ['initDictionary']),
    fetchRequiredData() {
      this.initDictionary().finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
