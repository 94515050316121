import { api } from '@/services/http';
import { formatDatesInPayload } from '@/utils';
import { Notification } from 'element-ui';

const normalizeData = (data) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    if (value === null) {
      delete data[key];
    }

    // TODO: На бекенде должны начать принимать null min and max
    if (key === 'limits') {
      if (!value.min && !value.max) {
        delete data[key];
        delete data.limitCurrency;
      }
    }
  }

  return formatDatesInPayload({ payload: data });
};

export default {
  namespaced: true,
  state: {
    pspFeeConfigs: [],
    pspFeeConfigsCount: 0,
    isLoadingDrawer: false,
    isVisibleDrawer: false,
    feeTableParams: {},
    editablePspFeeConfigId: null,
  },

  mutations: {
    setPspFeeConfigs: (state, { data = {}, count = 0 }) => {
      state.pspFeeConfigs = data;
      state.pspFeeConfigsCount = count;
    },
    setVisibleDrawer: (state, isVisibleDrawer = true) => {
      state.isVisibleDrawer = isVisibleDrawer;
    },
    setIsLoadingDrawer: (state, isLoading = true) => {
      state.isLoadingDrawer = isLoading;
    },
    setFeeTableParams: (state, params) => {
      state.feeTableParams = params;
    },
    setEditablePspFeeConfigId: (state, pspFeeConfigId = null) => {
      state.editablePspFeeConfigId = pspFeeConfigId;
    },
  },

  actions: {
    async createPspFeeConfig({ commit, dispatch }, formData) {
      try {
        await api.post('/finance/pspFeeConfig', normalizeData(formData));

        Notification.success({
          title: 'Success',
          message: 'Data saved',
        });

        commit('setVisibleDrawer', false);
        commit('setEditablePspFeeConfigId');
        dispatch('getPspFeeConfigs');
      } catch (e) {
        Notification.error({
          title: 'Error',
          message: e,
        });

        throw Error();
      }
    },
    async updatePspFeeConfig(
      { commit, dispatch },
      { pspFeeConfigId, formData },
    ) {
      try {
        await api.put(
          `/finance/pspFeeConfig/${pspFeeConfigId}`,
          normalizeData(formData),
        );

        Notification.success({
          title: 'Success',
          message: 'Data saved',
        });

        commit('setVisibleDrawer', false);
        commit('setEditablePspFeeConfigId');
        dispatch('getPspFeeConfigs');
      } catch (e) {
        Notification.error({
          title: 'Error',
          message: e,
        });

        throw Error();
      }
    },
    async getPspFeeConfigs({ commit, state }) {
      try {
        const { data, count } = await api.get('/finance/pspFeeConfig', {
          params: state.feeTableParams,
        });
        commit('setPspFeeConfigs', { data, count });
      } catch (e) {
        throw Error(e);
      }
    },
    async deletePspFeeConfig(ctx, id) {
      try {
        await api.delete(`/finance/pspFeeConfig/${id}`);
      } catch (e) {
        throw Error(e);
      }
    },
    async getPspFeeConfig({ commit }, pspFeeConfigId) {
      commit('setIsLoadingDrawer');

      try {
        const res = await api.get(`/finance/pspFeeConfig/${pspFeeConfigId}`);

        const { data: payload } = res;
        const data = formatDatesInPayload({
          payload,
          includeTime: true,
          unixToString: true,
        });

        if (!data.limits) {
          data.limits = {};
        }

        return data;
      } catch (error) {
        Notification.error({ title: 'Error', message: error?.message });
      } finally {
        commit('setIsLoadingDrawer', false);
      }
    },
  },
};
