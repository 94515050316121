<template>
  <div>
    <PspAccountInfo />
    <el-menu
      mode="horizontal"
      background-color="#F5F7FB"
      :default-active="$route.path"
      router
    >
      <el-menu-item
        v-for="item in menuItems()"
        :key="item.routeName"
        :index="item.path"
      >
        {{ item.label }}
      </el-menu-item>
    </el-menu>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PspAccountInfo from '@/components/PspAccountInfo.vue';

export default {
  name: 'PspAccountLayout',
  components: { PspAccountInfo },
  created() {
    this.fetchMandatoryData();
  },
  methods: {
    ...mapActions('pspAccounts', ['getPspAccount']),
    ...mapActions('dictionaryStore', [
      'getPspTransactionManualOperation',
      'getTransactionsTabOperations',
    ]),
    menuItems() {
      const { pspAccountId } = this.$route.params;

      return [
        {
          label: 'Reports',
          path: `/psp-accounts/${pspAccountId}/reports`,
          routeName: 'PSP_ACCOUNT_REPORTS',
        },
        {
          label: 'Transactions',
          path: `/psp-accounts/${pspAccountId}/transactions`,
          routeName: 'PSP_ACCOUNT_TRANSACTIONS',
        },
        {
          label: 'Top up balance transactions',
          path: `/psp-accounts/${pspAccountId}/top-up-balance-transactions`,
          routeName: 'PSP_ACCOUNT_TOP_UP_BALANCE_TRANSACTIONS',
        },
        {
          label: 'Fee configs',
          path: `/psp-accounts/${pspAccountId}/fee-configs`,
          routeName: 'PSP_ACCOUNT_FEE_CONFIGS',
        },
      ];
    },
    fetchMandatoryData() {
      this.getPspAccount(this.$route.params.pspAccountId);
      this.getTransactionsTabOperations();
      this.getPspTransactionManualOperation();
    },
  },
};
</script>
