<template>
  <AppTable
    table-name="QB Transfer info"
    :loading="isLoading"
    :data="quickBookTransferItem"
    :columns="columns"
    :show-pagination="false"
    @fetch-data="fetchData"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formatUnixToString } from '@/utils';
import AppTable from '@/components/app/table/AppTable.vue';

export default {
  name: 'QuickBookInfo',
  components: { AppTable },
  data: () => ({
    isLoading: true,
    columns: [
      {
        text: 'ID', value: 'id', visible: true, width: 100,
      },
      { text: 'External ID', value: 'externalId', visible: true },
      { text: 'Status', value: 'status', visible: true },
      {
        text: 'Company ID', value: 'companyId', visible: true, width: 100,
      },
      {
        text: 'Company', value: 'company', visible: true, width: 150,
      },
      {
        text: 'From account ID',
        value: 'fromAccountId',
        visible: true,
        width: 110,
      },
      {
        text: 'To account ID',
        value: 'toAccountId',
        visible: true,
        width: 100,
      },
      {
        text: 'From account name',
        value: 'fromAccountName',
        visible: true,
        width: 130,
      },
      {
        text: 'To account name',
        value: 'toAccountName',
        visible: true,
        width: 120,
      },
      {
        text: 'Currency',
        value: 'currency',
        visible: true,
        width: 70,
        align: 'center',
      },
      { text: 'Amount', value: 'amount', visible: true },
      {
        text: 'Amount (€)', value: 'amountEur', visible: true, width: 90,
      },
      {
        text: 'Exchange rate',
        value: 'exchangeRate',
        visible: true,
        width: 100,
      },
      {
        text: 'Private note', value: 'privateNote', visible: true, width: 100,
      },
      {
        text: 'TXN Date',
        value: 'txnDate',
        visible: true,
        formatDate: true,
        sortable: true,
        width: 135,
      },
      {
        text: 'Created',
        value: 'created',
        visible: true,
        formatDate: true,
        width: 135,
      },
      {
        text: 'Last updated',
        value: 'lastUpdated',
        visible: true,
        formatDate: true,
        width: 135,
      },
      {
        text: 'Amount to close',
        value: 'amountToClose',
        visible: true,
        width: 170,
      },
    ],
  }),
  computed: {
    ...mapState('quickBooksStore', ['quickBookTransferItem']),
  },
  methods: {
    ...mapActions('quickBooksStore', ['getQuickBookTransferRequest']),
    formatUnixToString,
    fetchData() {
      this.isLoading = true;

      this.getQuickBookTransferRequest().finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
