import axios from 'axios';
import { API_HOST } from '@/config';
import store from '@/store';
import { Notification } from 'element-ui';

const AUTH_TOKEN = 'AUTH_TOKEN';

const auth = {
  getToken: () => localStorage.getItem(AUTH_TOKEN),
  setToken: (token) => localStorage.setItem(AUTH_TOKEN, token),
  removeToken: () => localStorage.removeItem(AUTH_TOKEN),
};

const api = axios.create({
  baseURL: API_HOST,
});

api.interceptors.response.use(
  (response) => {
    switch (response.headers['content-type']) {
      case 'text/csv; charset=UTF-8':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return response.data;
    }
    if (response.headers['x-meta-count']) {
      return {
        data: response.data.data,
        count: Number(response.headers['x-meta-count']),
      };
    }
    if (response.status === 201) {
      return response.headers['content-location'];
    }
    if (response.status === 205) {
      return 'Successfully';
    }
    if (response.data.code !== 0) {
      Notification.error({
        title: 'Error',
        message: response.data.message,
      });

      return Promise.reject(response.data);
    }

    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('authorizationStore/logoutRequest', {
        hasTokenExpired: true,
      });
    }

    Notification.error({
      title: 'Error',
      message: error.response.data.message,
    });

    return Promise.reject(error.response.data.message);
  },
);

api.interceptors.request.use((config) => {
  const token = auth.getToken();

  if (token) {
    config.headers['X-Auth-Token'] = token;
  }

  return config;
});

export { api, auth };
