import { api } from '@/services/http';
import { Notification } from 'element-ui';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    actualSettlements: [],
    actualSettlementsCount: 0,
    actualSettlementTableParams: {},
  },
  mutations: {
    setActualSettlement: (state, { data, count }) => {
      state.actualSettlements = data;
      state.actualSettlementsCount = count;
    },
    setActualSettlementTableParams: (state, params = {}) => {
      if (!Object.keys(params).length) return;

      state.actualSettlementTableParams = params;
    },
  },
  actions: {
    async toggleNeedRecalculatePspTransactionRequest(
      _,
      { pspTransactionId, needRecalculate },
    ) {
      try {
        await api.patch(`/finance/pspTransaction/${pspTransactionId}`, {
          needRecalculate,
        });
      } catch (e) {
        throw Error();
      }
    },
    async toggleNeedRecalculateActualSettlementRequest(
      _,
      { actualSettlementId, needRecalculate },
    ) {
      const { pspAccountId } = router.currentRoute.params;

      try {
        await api.patch(
          `/pspAccount/${pspAccountId}/actualSettlement/${actualSettlementId}`,
          { needRecalculate },
        );
      } catch (e) {
        throw Error();
      }
    },
    async getActualSettlementsRequest({ commit, state }, params = {}) {
      const { pspAccountId } = router.currentRoute.params;

      commit('setActualSettlementTableParams', params);

      try {
        const { data, count } = await api.get('/actualSettlement', {
          params: {
            'filterList[pspAccountId]': pspAccountId,
            ...state.actualSettlementTableParams,
          },
        });

        commit('setActualSettlement', { data, count });
      } catch (error) {
        throw Error();
      }
    },
    async toggleVisaedRequest(
      { dispatch },
      { isVisaed, comment = null, actualSettlementId },
    ) {
      const { pspAccountId } = router.currentRoute.params;

      try {
        await api.patch(
          `/pspAccount/${pspAccountId}/actualSettlement/updateIsVisaed/${actualSettlementId}`,
          { isVisaed, comment },
        );

        dispatch('getActualSettlementsRequest');
      } catch (e) {
        Notification.error({
          title: 'Error',
          message: e,
        });
      }
    },
  },
};
