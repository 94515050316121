import { GameProvidersApi } from '@/api/game-providers.api';
import { Notification } from 'element-ui';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    gameProvider: {},
    gameProviderList: [],
    isLoadingGameProviderList: true,
    isLoadingGameProvider: false,
    isVisibleForm: false,
    editableGameProviderAccountId: null,
  },
  getters: {
    isEditableGameProviderForm: (state) => !!state.editableGameProviderAccountId,
  },
  mutations: {
    setGameProviderList: (state, data) => {
      state.gameProviderList = data;
    },
    setVisibleGameProviderForm: (state, isVisible) => {
      state.isVisibleForm = isVisible;
    },
    setGameProviderAccountIdToEdit: (state, gameProviderAccountId) => {
      state.editableGameProviderAccountId = gameProviderAccountId;
    },
    setLoadingGameProviderList: (state, isLoading) => {
      state.isLoadingGameProviderList = isLoading;
    },
    setLoadingGameProvider: (state, isLoading) => {
      state.isLoadingGameProvider = isLoading;
    },
    setGameProvider: (state, data) => {
      state.gameProvider = data;
    },
  },
  actions: {
    selectGameProviderAccountIdToEdit(
      { commit },
      gameProviderAccountId = null,
    ) {
      commit('setGameProviderAccountIdToEdit', gameProviderAccountId);
    },
    visibleGameProviderForm({ commit }, { isVisible }) {
      commit('setVisibleGameProviderForm', isVisible);
    },
    getGameProviderList({ commit }, params) {
      commit('setLoadingGameProviderList', true);

      GameProvidersApi.getGameProviderListRequest(params)
        .then((data) => {
          commit('setGameProviderList', data);
        })
        .finally(() => {
          commit('setLoadingGameProviderList', false);
        });
    },
    createGameProvider({ dispatch }, payload) {
      GameProvidersApi.createGameProviderRequest(payload)
        .then(() => {
          Notification.success({
            title: 'Successfully',
            message: 'Game provider data saved successfully',
          });

          dispatch('visibleGameProviderForm', { isVisible: false });
          dispatch('getGameProviderList');
        })
        .catch((e) => {
          throw Error(e);
        });
    },
    updateGameProvider({ state, dispatch }, payload) {
      GameProvidersApi.updateGameProviderRequest({
        payload,
        gameProviderAccountId: state.editableGameProviderAccountId,
      })
        .then(() => {
          Notification.success({
            title: 'Successfully',
            message: 'Game provider data saved successfully',
          });

          dispatch('visibleGameProviderForm', { isVisible: false });
          dispatch('selectGameProviderAccountIdToEdit');
          dispatch('getGameProviderList');
        })
        .catch((e) => {
          throw Error(e);
        });
    },
    deleteGameProvider({ dispatch }, gameProviderAccountId) {
      GameProvidersApi.deleteGameProviderRequest(gameProviderAccountId).then(
        () => {
          Notification.success({
            title: 'Successfully',
            message: 'Game provider successfully removed',
          });

          dispatch('getGameProviderList');
        },
      );
    },
    getGameProvider({ commit }) {
      commit('setLoadingGameProvider', true);
      const { gameProviderAccountId } = router.currentRoute.params;

      GameProvidersApi.getGameProviderRequest(gameProviderAccountId)
        .then((data) => {
          commit('setGameProvider', data);
        })
        .finally(() => {
          commit('setLoadingGameProvider', false);
        });
    },
  },
};
