import { api } from '@/services/http';
import { HttpMethods } from '@/models/http-method.model';
import { formatDatesInPayload } from '@/utils';
import { DictionaryName } from '@/models/dictionary.model';

const API_URL = '/gameProvider/gameProviderAccount';

export const GameProvidersApi = {
  getGpAccountFeeConfigListRequest: async ({
    params,
    gameProviderAccountId,
  }) => {
    const { data, count } = await api.request({
      url: `/gameProvider/gpAccountFeeConfigList/${gameProviderAccountId}`,
      method: HttpMethods.GET,
      params,
    });

    return { data, count };
  },
  getGpAccountFeeConfigRequest: async (gpAccountFeeId) => {
    const { data } = await api.request({
      url: `/gameProvider/gpAccountFeeConfig/${gpAccountFeeId}`,
      method: HttpMethods.GET,
    });

    return formatDatesInPayload({
      payload: data,
      includeTime: true,
      unixToString: true,
    });
  },
  createGpAccountFeeConfigRequest: async (payload) => {
    await api.request({
      url: '/gameProvider/gpAccountFeeConfig',
      method: HttpMethods.POST,
      data: formatDatesInPayload({ payload }),
    });
  },
  updateGpAccountFeeConfigRequest: async ({ payload, gpAccountFeeId }) => {
    await api.request({
      url: `/gameProvider/gpAccountFeeConfig/${gpAccountFeeId}`,
      method: HttpMethods.PATCH,
      data: formatDatesInPayload({ payload }),
    });
  },
  deleteGpAccountFeeConfigRequest: async (gpAccountFeeId) => {
    await api.request({
      url: `/gameProvider/gpAccountFeeConfig/${gpAccountFeeId}`,
      method: HttpMethods.DELETE,
    });
  },
  getGameProviderListRequest: async (params) => {
    const { data } = await api.request({
      url: API_URL,
      method: HttpMethods.GET,
      params: { limit: 1000, offset: 0, ...params },
    });

    return data;
  },
  createGameProviderRequest: async (payload) => {
    await api.request({
      url: API_URL,
      method: HttpMethods.POST,
      data: payload,
    });
  },
  updateGameProviderRequest: async ({ payload, gameProviderAccountId }) => {
    await api.request({
      url: `${API_URL}/${gameProviderAccountId}`,
      method: HttpMethods.PUT,
      data: payload,
    });
  },
  getGameProviderRequest: async (gameProviderAccountId) => {
    const { data } = await api.request({
      url: `${API_URL}/${gameProviderAccountId}`,
      method: HttpMethods.GET,
    });

    return data;
  },
  getGpGameFeatureGroupRequest: async ({ gpAccountId, gameProducerList }) => {
    const params = {
      'filterList[gameProducerIdList]': gameProducerList.map(
        (gameProducer) => gameProducer.id,
      ),
      'filterList[gpAccountId]': gpAccountId,
    };

    const { data } = await api.request({
      url: `/dictionary/${DictionaryName.GP_GAME_FEATURE_GROUP}`,
      method: HttpMethods.GET,
      params,
    });

    return data;
  },
  deleteGameProviderRequest: async (gameProviderAccountId) => {
    await api.request({
      url: `${API_URL}/${gameProviderAccountId}`,
      method: HttpMethods.DELETE,
    });
  },
};
