import moment from 'moment-timezone';

const DateTimeProperties = [
  'fromDateTime', // Удалить после переезда
  'toDateTime', // Удалить после переезда
  'fromCreatedAt',
  'toCreatedAt',
  'settlementFirstDateTime',
  'completedAt',
  'dateFrom',
  'dateTo',
];

export const formatUnixToString = (unixTimestamp, includeTime = false) => {
  if (!unixTimestamp) return null;

  const outputFormat = includeTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
  const momentUnix = moment.unix(unixTimestamp);

  return momentUnix.isValid() ? momentUnix.format(outputFormat) : null;
};

export const formatStringToUnix = (dateString) => {
  if (!dateString) return null;

  const momentString = moment(dateString);

  return momentString.isValid() ? momentString.unix() : null;
};

export const formatDatesInPayload = ({
  payload,
  includeTime = false,
  unixToString = false,
}) => {
  const clonedPayload = JSON.parse(JSON.stringify(payload));

  DateTimeProperties.forEach((prop) => {
    if (clonedPayload[prop] !== undefined) {
      clonedPayload[prop] = unixToString
        ? formatUnixToString(clonedPayload[prop], includeTime)
        : formatStringToUnix(clonedPayload[prop]);
    }
  });

  return clonedPayload;
};
