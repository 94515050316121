import { api } from '@/services/http';
import { HttpMethods } from '@/models/http-method.model';

export const DictionaryApi = {
  getDictionaryRequest: async (params) => {
    const { data } = await api.request({
      url: '/dictionary',
      method: HttpMethods.GET,
      params,
    });

    return data;
  },
};
